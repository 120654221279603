import _ from "lodash";
import colors from "@/assets/content/colors.json";

const state = {
  segments: [],
  segmentColors: [],
  features: { emotions: [], agreements: [] }
};

const mutations = {
  setSegments: (state, value) => {
    if (value.demographic) {
      state.segments = _.chain(value.demographic)
        .filter(d => d.segment !== "State Group") // TODO remove
        .map(d => d.segment)
        .value();
    } else if (value.agreements) {
      state.segments = _.chain(value.agreements)
        .map(a => a.segment)
        .uniqBy()
        .value();
    } else if (value.emotions) {
      state.segments = _.chain(value.emotions)
        .map(e => e.segment)
        .uniqBy()
        .value();
    }
  },
  setSegmentColors: state => {
    let colorIndex = -1;
    let res = [];
    _.forEach(state.segments, o => {
      if (o.toLowerCase().includes("all")) {
        // to FIX ?
        res.push({
          label: o,
          color: colors.all
        });
      } else {
        colorIndex++;
        res.push({
          label: o,
          color: colors.others[colorIndex]
        });
      }
    });
    state.segmentColors = res;
  },
  setEmotionFeatures: (state, value) => {
    state.features.emotions = _.chain(value.emotions)
      .groupBy(d => d.category_section)
      .keys()
      .value();
  },
  setAgreementFeatures: (state, value) => {
    state.features.agreements = _.chain(value.agreements)
      .groupBy(d => d.category_section)
      .keys()
      .value();
  }
};

const getters = {
  segments: (state) => state.segments,
  segmentColors: (state) => state.segmentColors,
  features: (state) => state.features
};

const actions = {
  setDomains({ commit }, value) {
    commit("setSegments", value);
    commit("setSegmentColors");
    commit("setEmotionFeatures", value);
    commit("setAgreementFeatures", value);
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
}
