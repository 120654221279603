import _ from 'lodash';

const state = {
  ancestors: null,
};

const mutations = {
  setAncestors: (state, value) => {
    state.ancestors = value;
  },
};

const getters = {
  getAncestors: (state) => state.ancestors,
};

const actions = {
  setAncestors ({commit}, value) {
    commit ('setAncestors', value);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
