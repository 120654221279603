<template>
  <div id="app" :class="$tvaMq">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import app from "@/backend";
export default {
  name: "App",
  created() {
    this.setContent();
    // Send user IDs according to:
    // https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites
    if(app.currentUser){
      let user = app.currentUser.customData.name || app.currentUser.email;
      gtag("config", "G-13CVF3QB64", {
        user_id: user
      });
      gtag("set", "user_properties", {
        crm_id: user
      });
    }
  },
  watch: {
    $route() {
      document.querySelector("body").style.overflow = "auto";
      // GA event on page change
      let obj = {
        event_label: this.$route.params.page || this.$route.name,
        value: this.$route.fullPath
      };
      gtag("event", "changePage", {
        event_label: obj.event_label,
        value: obj.value
      });
    }
  },
  methods: {
    ...mapActions(["setContent"])
  }
};
</script>

<style lang="scss">
// GLOBAL
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
</style>

<style lang="scss" scoped>
#app {
  *,
  ::before,
  ::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }
  .link {
    margin-right: 10px;
  }
  &.mobile {
    overflow-x: hidden;
  }
}
</style>
