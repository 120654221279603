import _ from 'lodash';
import app from '@/backend';

//#region DATA LOADING

import {ObjectId} from 'bson';

function loadData (reportId) {
  if (reportId === undefined) {
    return Promise.reject ();
  }
  return app.currentUser
    .mongoClient ('mongodb-atlas')
    .db ('Brand')
    .collection ('Report')
    .findOne ({_id: new ObjectId (reportId)});
}

//#endregion

// Content languages import
import english from '@/assets/content/english.json';
import spanish from '@/assets/content/spanish.json';

const content = {
  en: english,
  es: spanish,
};
// end content import

const state = {
  meta: {},
  demographic: [],
  emotions: [],
  agreements: [],
  conjoint: [],
  reportId: null,
  content: null,
};

const mutations = {
  setDemographic: (state, value) => {
    state.demographic = value;
  },
  setEmotions: (state, value) => {
    state.emotions = value;
  },
  setAgreements: (state, value) => {
    state.agreements = value;
  },
  setConjoint: (state, value) => {
    state.conjoint = value;
  },
  setReportId: (state, value) => {
    state.reportId = value;
  },
  setMetadata: (state, value) => {
    let fakeAD2Spanish = false;
    if(fakeAD2Spanish){
      console.log('!!!!!!!!!! FAKING SPANISH FOR AD2 REPORT ON !!!!!!!!!!!!!!!!!')
      value.language = 'es';
    }
    state.meta = value;
  },
  setContent: state => {
    let lang = state.meta.language;
    if (lang) {
      state.content = content[lang];
    } else {
      state.content = content.en;
    }
    // To test spanish content:
    // state.content = content.es; 
  },
};

const getters = {
  metadata: (state) => state.meta,
  demographic: (state) => state.demographic,
  emotions: (state) => state.emotions,
  agreements: (state) => state.agreements,
  conjoint: (state) => state.conjoint,
  reportId: (state) => state.reportId,
  content: (state) => state.content,
};

const actions = {
  setDemographic ({commit}, value) {
    commit ('setDemographic', value);
  },
  setEmotions ({commit}, value) {
    commit ('setEmotions', value);
  },
  setAgreements ({commit}, value) {
    commit ('setAgreements', value);
  },
  setConjoint ({commit}, value) {
    commit ('setConjoint', value);
  },
  loadReport({ commit, state }, id) {
    if (state.reportId !== id) {
      loadData (id).then (result => {
        const {data, ...meta} = result;
        const {demographic, emotions, agreements, conjoint} = data;
        commit ('setDemographic', demographic);
        commit ('setEmotions', emotions);
        commit ('setAgreements', agreements);
        commit ('setConjoint', conjoint);
        commit ('setSegments', {demographic, emotions, agreements});
        commit ('setSegmentColors');
        commit ('setEmotionFeatures', {demographic, emotions, agreements});
        commit ('setAgreementFeatures', {demographic, emotions, agreements});
        commit ('setMetadata', meta);
        commit ('setReportId', id);
        commit ('setContent');
      });
    }
  },
  setContent({commit}) {
    commit ('setContent');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
