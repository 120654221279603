import { createStore } from 'vuex'

import domains from './modules/domains';
import data from './modules/data';
import selections from './modules/selections';
import ui from './modules/ui';
import navigation from './modules/navigation';


export default createStore({
  modules: {
    domains,
    data,
    selections,
    ui,
    navigation,
  },
});
