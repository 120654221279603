import _ from 'lodash';

const state = {
  segmentSelected: null,
  orderSelected: null,
  featureSelected: {agreements: null},
  emotionValueSelected: null,
  vizTypeSelectedAuthenticity: null,
  vizTypeSelectedConjoint: null,
  agreementSelected: null,
  range: [0, 7],
  demographicSegmentsSelected: [],
  additionalSegmentStore: null,
};

const mutations = {
  setSegmentSelected: (state, value) => {
    state.segmentSelected = value;
  },
  setOrderSelected: (state, value) => {
    state.orderSelected = value;
  },
  setFeatureSelected: (state, value) => {
    if (value) {
      state.featureSelected[value.key] = value.value;
    } else {
      state.featureSelected = {agreements: null};
    }
  },
  setEmotionValueSelected: (state, value) => {
    state.emotionValueSelected = value;
  },
  setVizTypeSelectedAuthenticity: (state, value) => {
    state.vizTypeSelectedAuthenticity = value;
  },
  setVizTypeSelectedConjoint: (state, value) => {
    state.vizTypeSelectedConjoint = value;
  },
  setAgreementSelected: (state, value) => {
    state.agreementSelected = value;
  },
  setRange: (state, value) => {
    state.range = value;
  },
  resetDemographicSegment: state => {
    state.demographicSegmentsSelected = [];
  },
  toggleDemographicSegment: (state, value) => {
    let index = state.demographicSegmentsSelected.indexOf (value);

    if (index >= 0) {
      state.demographicSegmentsSelected.splice (index, 1);
    } else {
      state.demographicSegmentsSelected.push (value);
    }
  },
  setAdditionalSegmentStore: (state, value) => {
    state.additionalSegmentStore = value;
  },
};

const getters = {
  segmentSelected: (state) => state.segmentSelected,
  orderSelected: (state) => state.orderSelected,
  featureSelected: (state) => state.featureSelected,
  emotionValueSelected: (state) => state.emotionValueSelected,
  vizTypeSelectedAuthenticity: (state) => state.vizTypeSelectedAuthenticity,
  vizTypeSelectedConjoint: (state) => state.vizTypeSelectedConjoint,
  agreementSelected: (state) => state.agreementSelected,
  range: (state) => state.range,
  demographicSegmentsSelected: (state) => state.demographicSegmentsSelected,
  additionalSegmentStore: (state) => state.additionalSegmentStore,
};

const actions = {
  setSegmentSelected ({commit}, value) {
    commit ('setSegmentSelected', value);
  },
  setOrderSelected ({commit}, value) {
    commit ('setOrderSelected', value);
  },
  setFeatureSelected ({commit}, value) {
    commit ('setFeatureSelected', value);
  },
  setEmotionValueSelected ({commit}, value) {
    commit ('setEmotionValueSelected', value);
  },
  setVizTypeSelectedAuthenticity ({commit}, value) {
    commit ('setVizTypeSelectedAuthenticity', value);
  },
  setVizTypeSelectedConjoint ({commit}, value) {
    commit ('setVizTypeSelectedConjoint', value);
  },
  setAgreementSelected ({commit}, value) {
    commit ('setAgreementSelected', value);
  },
  setRange ({commit}, value) {
    commit ('setRange', value);
  },
  toggleDemographicSegment ({commit}, value) {
    commit ('toggleDemographicSegment', value);
  },
  resetDemographicSegment({commit}) {
    commit ('resetDemographicSegment');
  },
  setAdditionalSegmentStore ({commit}, value) {
    commit ('setAdditionalSegmentStore', value);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
