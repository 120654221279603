import _ from 'lodash';

const state = {
  isMenuOpen: false,
  isPrinting: false,
  vizTooltip: null,
  mainHeaderVisible: true,
  isFilterDropdownOpen: false,
};

const mutations = {
  handleMenu: state => {
    state.isMenuOpen = !state.isMenuOpen;
  },
  setIsPrinting: (state, value) => {
    state.isPrinting = value;
  },
  setVizTooltip: (state, value) => {

  
    if (value === null) {
      state.vizTooltip = null;
    
    } else {

      let res = {
        position: {
          x: 0,
          y: 0
        },
        content: [],
      };
      if (!value.mobile) {
        const elDim = value.event.target.getBoundingClientRect();
        res.position.x = elDim.x + elDim.width / 2;
        res.position.y = elDim.y - 10;
      }
      else { 
        //position coords for mobile tooltip
        res.position.x =  value.event.pageX;
        res.position.y = value.event.pageY - 30;
      }

      value.data.forEach(d => {
        res.content.push(d);
      });

      state.vizTooltip = res;
    }
  },
  setMainHeaderVisible: (state, value) => {
    state.mainHeaderVisible = value;
  },
  setFilterDropdownOpen: (state, value) => {
    state.isFilterDropdownOpen = value;
  },
};

const getters = {
  isMenuOpen: (state) => state.isMenuOpen,
  isPrinting: (state) => state.isPrinting,
  vizTooltip: (state) => state.vizTooltip,
  mainHeaderVisible: (state) => state.mainHeaderVisible,
  isFilterDropdownOpen: (state) => state.isFilterDropdownOpen,
};

const actions = {
  setMenuOpen({ commit }) {
    commit('handleMenu');
  },
  setIsPrinting({ commit }, value) {
    commit('setIsPrinting', value);
  },
  setVizTooltip({ commit }, event, data) {
    commit('setVizTooltip', event, data);
  },
  setMainHeaderVisible({ commit }, value) {
    commit('setMainHeaderVisible', value);
  },
  setFilterDropdownOpen({ commit }, value) {
    commit('setFilterDropdownOpen', value);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
