import { createApp } from 'vue'
import App from './App.vue';

// Store
import store from './store'

// Router
import router from './router';

// Accordion
import VShowSlide from 'vue-show-slide';

// Scroll to
import VueScrollTo from 'vue-scrollto';


// Js Media queries
import tvaMq from './tvaMq';


// Touch gestures
import Vue3TouchEvents from 'vue3-touch-events';

// Svg icon
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import "@yzfe/svgicon/lib/svgicon.css";

// REALM
// Import this to ensure it gets initialized before Vue
import "./backend.js"

const app = createApp(App)

app.use(VueSvgIconPlugin, { tagName: "icon" });
app.use(VShowSlide)
app.use(Vue3TouchEvents)
app.use(VueScrollTo, {
  container: 'body',
  duration: 300,
  easing: 'ease',
  offset: -100,
});

app.use(tvaMq, {
  mobile: 768,
  tablet: 768,
  desktop: Infinity,
  large: Infinity,
});

app.use(store)
app.use(router);

app.mount('#app')
