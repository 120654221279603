import {
  createRouter,
  createWebHashHistory,
} from "vue-router";

import app from './backend';
import content from './assets/content/english.json';

const basePath = process.env.BASE_URL;

const router = createRouter({
  history: createWebHashHistory(basePath),
  routes: [
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/components/pages/Reports-page.vue'),
      props () {
        const props = {
          content: {...content.pages.common, ...content.pages.reportsHome},
        };
        return props;
      },
    },
    {
      path: '/',
      redirect: {
        name: 'reports',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/components/pages/Login-page.vue'),
      meta: {
        anonymous: true,
      },
      props () {
        const props = {
          content: {...content.pages.login, ...content.pages.common},
        };
        return props;
      },
    },
    {
      path: '/account/activate',
      name: 'activate-account',
      component: () => import('@/components/pages/AccountActivate-page.vue'),
      meta: {
        anonymous: true,
      },
      props () {
        const props = {
          content: {...content.pages.accountActivate, ...content.pages.common},
        };
        return props;
      },
    },
    {
      path: '/account/recover',
      name: 'recover-password',
      component: () => import('@/components/pages/AccountRecover-page.vue'),
      meta: {
        anonymous: true,
      },
      props () {
        const props = {
          content: {...content.pages.accountRecover, ...content.pages.common},
        };
        return props;
      },
    },
    {
      path: '/account/reset',
      name: 'reset-password',
      component: () => import('@/components/pages/AccountReset-page.vue'),
      meta: {
        anonymous: true,
      },
      props () {
        const props = {
          content: {...content.pages.accountReset, ...content.pages.common},
        };
        return props;
      },
    },
    {
      path: '/account/changepassword',
      name: 'change-password',
      component: () => import('@/components/pages/ChangePassword-page.vue'),
      props () {
        const props = {
          content: {...content.pages.accountChangePassword, ...content.pages.common},
        };
        return props;
      },
    },
    {
      path: '/home/:report?',
      name: 'report-home',
      component: () => import('@/components/pages/ReportHome-page.vue'),
      // props () {
      //   const props = {
      //     content: store.getters.content,
      //   };
      //   return props;
      // },
    },
    {
      path: '/home/:report/:page',
      name: 'home',
      component: () => import('@/components/pages/Home-page.vue'),
      // props () {
      //   const props = {
      //     content: store.getters.content,
      //   };
      //   return props;
      // },
    },
  ]
});

router.beforeEach ((to, from, next) => {
  if (app.currentUser || to.meta.anonymous) {
    next ();
  } else {
    next ({name: 'login'});
  }
});

export default router